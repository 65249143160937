<template>
  <v-col cols="11" md="8">

    <v-card
      :class="theme.opacityFormBox ? 'cts-login-opacity' : ''"
      class="pa-5 cts-login-box"
    >
      <v-row
        class="ma-0 pa-0"
        dense
      >
        <v-col
          class="mt-0 mb-4 mx-0 pa-0"
          cols="12"
        >
          <h1 class="cts-font-size-2 font-weight-regular">{{$vuetify.lang.t('$vuetify.loginTitleText')}}</h1>
        </v-col>
      </v-row>
      <v-row dense>
        <p class="cts-font-size-3">
          {{$vuetify.lang.t('$vuetify.loginCasText')}}</p>
      </v-row>
      <v-row
        class="ma-0"
        dense
      >

        <v-btn
          id="login_button"
          :loading="loading"
          class="mt-4 cts-button-login px-0"
          @click="loginCas()"
        >
          {{$vuetify.lang.t('$vuetify.loginCasButton')}}
          <span slot="loader">
          <v-progress-circular class="mr-2" color="white" indeterminate size="20"></v-progress-circular>
          <span>{{$vuetify.lang.t('$vuetify.redirecting')}}...</span>
        </span>
        </v-btn>
        <v-expand-transition>
          <v-col cols="12">
            <v-row
              v-if="message != ''"
              class="ma-0 mt-2 justify-center"
              dense
            >
            <span
              id="login_error_message"
              class="caption"
            >{{message}}</span>
            </v-row>
          </v-col>
        </v-expand-transition>

      </v-row>
    </v-card>
  </v-col>

</template>
<script>
import {loginCas} from "@/services/userServices";

export default {
  name: "CasLogin",
  props: {
    theme: String
  },
  data() {
    return {
      message: "",
      loading: false,
      loginUserSuccess: null
    }
  },
  created() {
    if (this.$route.query?.token) {
      this.loading = true
    }
  },
  methods: {
    loginCas() {
      this.loading = true
      this.message = this.$vuetify.lang.t('$vuetify.loginCasRedirectMessage')
      loginCas().then(response => {
        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl

        } else if (response.data.extra?.token) {
          this.loginUserSuccess(response.data.extra.token)
          this.$router.push({query: {}})
        }

      })
    },
  }
}
</script>

<style lang="scss" scoped>

</style>