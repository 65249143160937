<template>
  <v-card flat class="cts-tab-item-content">
    <v-card-title class="cts-tab-title mx-3 mb-3 py-0 px-2 hidden-xs-only">
      <h4>
        <v-icon left size="19" class="mr-1">{{icon}}</v-icon>
        {{title}}
      </h4>
    </v-card-title>
    <v-card-text class="cts-tab-content pa-0 px-sm-4">
      <p>{{$vuetify.lang.t('$vuetify.adminGroupInfoLinked')}}</p>
      <v-row class="ma-0 pa-0" dense>
        <v-col cols="12" lg="3" xl="3" md="12" sm="12" class="px-0 offset-lg-9">
          <v-row dense class="ma-0 justify-end">
            <v-text-field
            multiple
            clearable
            single-line
            outlined
            dense
            class="ma-0 cts-text-box"
            v-model="search"
            :placeholder="$vuetify.lang.t('$vuetify.search')"
            :append-icon="const_icons.SEARCH"
            :aria-label="$vuetify.lang.t('$vuetify.search')"
            >
            </v-text-field>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0" dense>
        <v-col cols="12" md="12" sm="12" class="px-0">
          <v-data-table
            dense
            :headers="headers"
            :items="linkedGroups"
            :search="search"
            class="elevation-0 col-12 pa-0 pa-sm-3"
            sort-by="name"
            calculate-widths
            :footer-props="{
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'keyboard_arrow_left',
                nextIcon: 'keyboard_arrow_right',
                itemsPerPageText: $vuetify.lang.t('$vuetify.groupsPerPage'),
                pageText: `${$vuetify.lang.t('$vuetify.groupsFound')} ${totalGroupsFound} ${$vuetify.lang.t('$vuetify.groups')}`
              }"
            @current-items="handleItemChange"
            @pagination="totalNumberOfItems"
            >
                    <!-- pageText: `${$vuetify.lang.t('$vuetify.groupsFound')} ${(linkedGroups.length > 0 ? linkedGroups.length - 1 : linkedGroups.length)} ${$vuetify.lang.t('$vuetify.groups')}` -->
            <template v-slot:item="{item}">
              <tr class="hidden-xs-only" v-if="item.name != group.group_name">
                <td class="text-start cts-group-linked-name-column">
                  <v-tooltip v-if="item.name.length > 15" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h4 class="cts-color-tertiary cts-break-word" v-bind="attrs" v-on="on">{{item.name}}</h4>
                    </template>
                    <span>{{ item.name  }}</span>
                  </v-tooltip>
                  <h4 v-else class="cts-color-tertiary cts-break-word">{{ item.name }}</h4>
                </td>
                <td class="text-start cts-group-linked-description-column">
                  <v-tooltip v-if="item.description.length > 15" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <h4 class="cts-color-tertiary cts-break-word" v-bind="attrs" v-on="on">{{item.description}}</h4>
                    </template>
                    <span>{{ item.description  }}</span>
                  </v-tooltip>
                  <h4 v-else class="cts-color-tertiary cts-break-word">{{ item.description }}</h4>
                </td>
                <td class="text-center">
                  <v-simple-checkbox
                    :ripple="false"
                    :disabled="!checkPermission(const_permissions.GROUP_EDIT_LINKED_GROUPS)"
                    class="cts-group-linked"
                    color="var(--primary)"
                    aria-label="checkbox-linked-group"
                    v-model="item.linked_group"
                    @click="$emit('change')"
                  />
                </td>
              </tr>
              <v-card class="mx-auto my-2 cts-card-transaction hidden-sm-and-up" max-width="100%" outlined>
                <v-list-item class="cts-list-item-card">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row dense class="ma-0 justify-space-between">
                        <v-col cols="10" class="hidden-sm-and-up pa-0 ma-0">
                          <v-tooltip v-if="item.name.length > 15" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <h4
                                class="cts-color-tertiary cts-break-word text-truncate"
                                v-bind="attrs"
                                v-on="on"
                                style="max-width: 300px !important"
                              >
                                {{item.name}}
                              </h4>
                            </template>
                            <span>{{ item.name }}</span>
                          </v-tooltip>
                          <h4 v-else class="cts-font-size-5 cts-white-space pa-1 cts-color-tertiary cts-break-word">
                            <span class="font-weight-bold mr-1">{{ item.name }}</span>
                          </h4>
                        </v-col>
                        <v-col cols="2">
                          <v-simple-checkbox
                            :ripple="false"
                            :disabled="!checkPermission(const_permissions.GROUP_EDIT_LINKED_GROUPS)"
                            class="cts-group-linked"
                            color="var(--primary)"
                            v-model="item.linked_group"
                            @click="$emit('change')"
                          />
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <v-row dense class="ma-0 justify-space-between">
                        <v-col cols="12" class="hidden-sm-and-up pa-0 ma-0">
                          <h4 class="cts-font-size-5 cts-white-space pa-1"><span class="mr-2">{{ item.description }}</span></h4>
                        </v-col>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                </v-list-item>
              </v-card>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import const_icons from '@/constants/icons';
  import { checkPermission } from "@/util/store_utils"
  import const_permissions from "@/constants/permissions";

  export default {
    name: "adminLinkedGroups",
    props: {
      icon: String,
      title: String,
      available_groups: Array,
      group: Object
    },
    computed: {
      nOfGroups() {
        return this.nGroups
      }
    },
    data() {
      return {
        const_icons,
        checkPermission,
        const_permissions,
        search: '',
        headers: [
          {
            text: this.$vuetify.lang.t('$vuetify.groupTitle'),
            align: 'start',
            value: 'name',
            width: '30%',
            class: "hidden-xs-only"
          },
          {
            text: this.$vuetify.lang.t('$vuetify.description'),
            align: 'start',
            value: 'description',
            width: '30%',
            class: "hidden-xs-only"
          },
          {
            text: this.$vuetify.lang.t('$vuetify.groupLinkedHeader'),
            align: 'center',
            value: 'linked_group',
            width: '10%', class: "hidden-xs-only"
          }
        ],
        linkedGroups: [],
        nGroups: 0,
        totalGroupsFound: 0
      }
    },
    methods: {
      sliceOwngroup() {
        this.available_groups.map((x) => {
            if (this.group.group_name !== x.name) {
              this.linkedGroups.push(x)
            }
          }
        )
      },

      handleItemChange(pag) {
        this.nGroups = pag.length
      },

      totalNumberOfItems(numItems) {
        return this.totalGroupsFound = numItems.itemsLength
      }
    },

    created() {
      this.sliceOwngroup()
    }
  }
</script>
