import Processes from "../views/Processes"
import Transactions from "../views/Transactions.vue"
import Statistics from "../views/Statistics"
import Support from "../views/Support"
import Contacts from "../views/Contacts"
import Administration from "../views/Administration"
import Account from "../views/Account"
import GroupsViewer from "../components/sections/administration/GroupsViewer"
import UsersViewer from "../components/sections/administration/UsersViewer"
import UserManagement from "../views/UserManagement"
import Forbidden from "../views/errors/Forbidden"
import TransactionView from "@/components/sections/transactions/transactionsView"
import GroupView from "../components/sections/administration/groupsView/groupView"
import UserView from "../components/sections/administration/usersView/userView"
import WithoutSubaccounts from "../views/errors/WithoutSubaccounts"
import Error500 from "../views/errors/Error500"
import ROUTES from "@/constants/router/routes"
import ProcessLaunch from '@/components/sections/processLaunch/processLaunch'
import Batches from "@/views/Batches.vue";

const routes = [
  {
    path: ROUTES.ROOT.PATH,
    name: ROUTES.ROOT.NAME,
    component: Processes,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: true
    }
  },
  {
    path: ROUTES.LOGIN.PATH,
    name: ROUTES.LOGIN.NAME,
    component: UserManagement,
    meta: {
      navigable: false,
      requiresAuth: false,
      subAccountDepends: false
    }
  },
  {
    path: ROUTES.LOGIN_CAS.PATH,
    name: ROUTES.LOGIN_CAS.NAME,
    component: UserManagement,
    meta: {
      navigable: false,
      requiresAuth: false,
      subAccountDepends: false
    }
  },

  {
    path: ROUTES.CHANGE_PASSWORD.PATH,
    name: ROUTES.CHANGE_PASSWORD.NAME,
    component: UserManagement,
    meta: {
      navigable: false,
      requiresAuth: false,
      subAccountDepends: false
    }
  },
  {
    path: ROUTES.NEW_PASSWORD.PATH + "/:code",
    name: ROUTES.NEW_PASSWORD.NAME,
    component: UserManagement,
    meta: {
      navigable: false,
      requiresAuth: false,
      subAccountDepends: false
    }
  },
  {
    path: ROUTES.ACTIVATE_USER.PATH + "/:code",
    name: ROUTES.ACTIVATE_USER.NAME,
    component: UserManagement,
    meta: {
      navigable: false,
      requiresAuth: false,
      subAccountDepends: false
    }
  },
  {
    path: ROUTES.REMEMBER_PASSWORD.PATH,
    name: ROUTES.REMEMBER_PASSWORD.NAME,
    component: UserManagement,
    meta: {
      navigable: false,
      requiresAuth: false,
      subAccountDepends: false
    }
  }, {
    path: ROUTES.PROCESS_VIEW.PATH,
    name: ROUTES.PROCESS_VIEW.NAME,
    component: ProcessLaunch,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: true
    }
  }, {
    path: ROUTES.PROCESSES.PATH,
    name: ROUTES.PROCESSES.NAME,
    component: Processes,
    meta: {
      navigable: true,
      requiresAuth: true,
      subAccountDepends: true
    }
  }, {
    path: "/",
    component: Processes,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: true
    }
  }, {
    path: ROUTES.TRANSACTIONS.PATH,
    name: ROUTES.TRANSACTIONS.NAME,
    component: Transactions,
    params: {
      page: 1
    },
    meta: {
      navigable: true,
      requiresAuth: true,
      subAccountDepends: true
    }
  }, {
    path: ROUTES.TRANSACTION_VIEW.PATH,
    name: ROUTES.TRANSACTION_VIEW.NAME,
    component: TransactionView,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: true
    }
  }, {
    path: ROUTES.BATCHES.PATH,
    name: ROUTES.BATCHES.NAME,
    component: Batches,
    meta: {
      navigable: true,
      requiresAuth: true,
      subAccountDepends: true
    }
  }, {
    path: ROUTES.STATISTICS.PATH,
    name: ROUTES.STATISTICS.NAME,
    component: Statistics,
    meta: {
      navigable: true,
      requiresAuth: true,
      subAccountDepends: true
    }
  }, {
    path: ROUTES.SUPPORT.PATH,
    name: ROUTES.SUPPORT.NAME,
    component: Support,
    meta: {
      navigable: true,
      requiresAuth: true,
      subAccountDepends: true
    }
  }, {
    path: ROUTES.CONTACTS.PATH,
    name: ROUTES.CONTACTS.NAME,
    component: Contacts,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: true
    }
  },
  {
    path: ROUTES.ADMINISTRATION.PATH,
    name: ROUTES.ADMINISTRATION.NAME,
    component: Administration,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: false
    }
  },
  {
    path: `${ROUTES.ACCOUNT.PATH}/:section?`,
    name: ROUTES.ACCOUNT.NAME,
    component: Account,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: false
    }
  },
  {
    path: ROUTES.GROUPS.PATH,
    name: ROUTES.GROUPS.NAME,
    component: GroupsViewer,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: false
    }
  }, {
    path: ROUTES.GROUP_VIEW.PATH,
    name: ROUTES.GROUP_VIEW.NAME,
    component: GroupView,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: false
    }
  },
  {
    path: ROUTES.USERS.PATH,
    name: ROUTES.USERS.NAME,
    component: UsersViewer,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: false
    }
  }, {
    path: ROUTES.USER_VIEW.PATH,
    name: ROUTES.USER_VIEW.NAME,
    component: UserView,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: false
    }
  },
  {
    path: ROUTES.FORBIDDEN.PATH,
    name: ROUTES.FORBIDDEN.NAME,
    component: Forbidden,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: false
    }
  },
  {
    path: ROUTES.WITHOUT_SUBACCOUNTS.PATH,
    name: ROUTES.WITHOUT_SUBACCOUNTS.NAME,
    component: WithoutSubaccounts,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: false
    }
  },
  {
    path: ROUTES.ERROR_500.PATH,
    name: ROUTES.ERROR_500.NAME,
    component: Error500,
    meta: {
      navigable: false,
      requiresAuth: true,
      subAccountDepends: false
    }
  },
]
export default routes;
