<template>
    <div contenteditable="false" class="chip-wrapper non-editable">
      <span>
          <span class="no-visible"> %%{{ value }}- </span>
          <span> {{ name }} </span>
          <span class="no-visible"> %% </span>
      </span>
    </div>
</template>

<script>
export default {
  name: 'chiptextDataField',
  props: {
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },
}

</script>

<style lang="scss" scoped>
.no-visible {
  display: inline-flex;
  align-items: center;
  visibility: hidden;
  width: 0;
  height: 0;
}

.chip-wrapper {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--primary) !important;
  background-color: var(--clear) !important;
  border: 1.5px solid var(--primary) !important;
  border-radius: 4px;
  max-height: 19px;
  font-weight: bold;
  font-size: 13px;
  padding: 2px;
}

.non-editable {
  pointer-events: none;
  user-select: none;
}

</style>
