<template>
  <v-container
    v-if="transactionModel === 'digital_signature'"
    class="cts-main-view col-12 transaction-view-container pt-2">
    <loading-container v-if="loading"/>
    <v-fade-transition>
      <v-row v-if="!loading && transaction_content" class="ma-0" dense justify="center">
        <v-col cols="11" lg="10" xl="10">
          <v-row class="ma-0 pa-0 justify-center" dense>
            <v-col class="mx-auto cts-view-header">
              <v-row class="cts-breadcrumbs ma-0 mt-1 pa-0" dense>
                <a :title="$vuetify.lang.t('$vuetify.allTransactions')" @click.prevent="backToMenu()">{{
                    $vuetify.lang.t('$vuetify.transactions')
                  }} </a>
                <span class="cts-color-black_soft_text font-weight-regular ml-1">/ {{
                    $vuetify.lang.t('$vuetify.transactionDetails')
                  }} </span>
              </v-row>
              <view-title
                :icon="const_icons.TRANSACTION"
                :right-link="{
                            text: $vuetify.lang.t('$vuetify.refresh'),
                            icon: 'refresh',
                            event: 'refresh'
                          }"
                :subtitle="`${transaction_content.translated_process_name} | ${$vuetify.lang.t('$vuetify.transactionId')}: ${transaction_content.transaction_id.split('.').pop()}`"
                :title="transactionStatus.id !== 'invalid' && transaction_content.process_name !== transaction_content.transaction_name ? transaction_content.transaction_name:$vuetify.lang.t('$vuetify.transactionDetails')"
                idName="transaction-detail"
                @refresh="getTransactionData()"
              />
            </v-col>
          </v-row>
          <v-row class="pa-0 pa-lg-6" dense>
            <v-col class="pr-lg-12" cols="12" lg="9">
              <v-row class="mb-4" dense>
                <TitleCard :title="$vuetify.lang.t('$vuetify.transactionDetails')" icon="info_outline"/>
              </v-row>
              <v-row dense>
                <v-col class="pl-md-10">
                  <v-row dense>
                    <v-col cols="12" md="4">
                      <DetailCard
                        :icon="transactionStatus.icon"
                        :icon-color="transactionStatus.color"
                        :title="$vuetify.lang.t('$vuetify.transactionState')"
                      >
                        <template v-slot:content>
                        <span>
                          <span
                            :style="'color:'+transactionStatus.color"
                            style="font-weight: bolder">{{transactionStatus.text}}</span>:
                            <span style="color: var(--tertiary)">{{transaction_content.state | capitalize}}</span>
                        </span>
                        </template>
                      </DetailCard>
                    </v-col>
                    <v-col cols="12" md="4">
                      <DetailCard
                        :extra="transaction_content.username"
                        :subtitle="`${dayjs.unix(transaction_content.creation_timestamp).format(const_global.DATE_TIME_FORMAT_A)}  (${dayjs.unix(transaction_content.creation_timestamp).fromNow()})`"
                        :title="$vuetify.lang.t('$vuetify.creation')"
                      />
                    </v-col>
                    <v-col cols="12" md="4">
                      <DetailCard
                        :extra="transaction_content.last_event ? capitalize(transaction_content.last_event) : '-'"
                        :subtitle="transaction_content.last_event_ts ? `${dayjs.unix(transaction_content.last_event_ts).format(const_global.DATE_TIME_FORMAT_A)}  (${dayjs.unix(transaction_content.last_event_ts).fromNow()})` : 'Sin datos'"
                        :title="$vuetify.lang.t('$vuetify.transactionLastEvent')"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="transactionStatus.id === 'invalid'" class="pl-md-12 my-2" dense>
                    <v-alert class="cts-font-size-3" color="var(--primary)" dense
                             icon="mdi-information-variant"
                             outlined>
                      {{$vuetify.lang.t('$vuetify.transactionInvalidAlert')}}
                    </v-alert>
                  </v-row>
                  <TransactionSectionDigitalSignature
                    v-else-if="transactionModel==='digital_signature'"
                    :transaction_content="transaction_content"/>
                  <v-row
                    v-else-if="filteredTransactionParameters && filteredTransactionParameters.length !== 0"
                    dense>
                    <v-col class="pa-0">
                      <v-divider class="my-4"/>
                      <v-row class="px-8" dense>
                        <span
                          class="text-uppercase cts-color-tertiary">
                            {{$vuetify.lang.t('$vuetify.transactionEntryData')}}
                        </span>
                      </v-row>
                      <v-row class="pl-md-12" dense>
                        <v-col class="pl-2 transaction-parameters" cols="8">
                          <v-row v-for="(parameter, index) in filteredTransactionParameters"
                                 :key="index"
                                 dense>
                            <span class="mr-2">{{parameter.translation}}:</span>
                            <span
                              class="font-weight-medium">
                              {{parameter.value}}
                            </span>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <TitleCard :title="$vuetify.lang.t('$vuetify.transactionInterveners')" class="my-4"
                         icon="people_outline"/>
              <v-row class="my-4" dense justify="end">
                <v-col v-if="transactionStatus.id !== 'invalid'" class="pa-0 pl-md-12" cols="12">
                  <IntervenerCard
                    v-if="transaction_content.recipient_data && typeof transaction_content.recipient_data !== 'string' && transaction_content.recipient_data.contact_id"
                    :intervener="transaction_content.recipient_data"
                    type="contact"/>
                  <IntervenerCard
                    v-for="(intervener, index) in transaction_content.interveners"
                    :key="index"
                    :action-diabled="false"
                    :index="index"
                    :intervener="intervener"
                    :sign-ceremony=" transactionStatus.id === 'pending' && transaction_content.parameters.find(p => p.key === 'signature_type').value === 'face-to-face'"
                    :sign-ceremony-enabled="transactionStatus.id === 'pending' && transaction_days.map(d => d.events).flat().filter(e => intervener.client_id === e.intervener).find(e => e.key == 'front_renderer_builder.intervener_finished') === undefined"
                    :transaction-finish="transactionStatus.id !== 'pending'"
                    type="intervener"
                  />
                </v-col>
                <v-col v-else class="pl-md-14">
                                <span
                                  class="cts-color-tertiary cts-font-size-3">{{$vuetify.lang.t('$vuetify.intervenersNotDetected')}}</span>
                </v-col>
              </v-row>
              <v-row v-if="checkPermission(const_permissions.TRANSACTION_SHOW_EVENTS)" class="my-4" dense>
                <v-col class="pa-0">
                  <TitleCard
                    :extra="`${transaction_content.events ? transaction_content.events.length : 0} ${$vuetify.lang.t('$vuetify.events')}`"
                    :icon="const_icons.TRANSACTION"
                    :title="$vuetify.lang.t('$vuetify.transactionEventsRegistered')"
                  />
                  <v-col
                    v-if="transaction_days.length !== 0 && checkPermission(const_permissions.TRANSACTION_SHOW_EVENTS)"
                    class="pa-0 pl-md-12 mt-4 transaction-events-container"
                  >
                    <v-row class="px-2" dense justify="end">
                      <a class="link-help cts-link cts-font-size-3 cts-color-primary-alternative"
                         title="Información sobre los eventos"
                         @click="eventsInfoDialog = true">
                        <v-icon class="mr-1" color="var(--primary-alternative)" size="20">
                          mdi-information-variant
                        </v-icon>
                        {{$vuetify.lang.t('$vuetify.aboutEvents')}}
                      </a>
                    </v-row>
                    <v-row class="d-md-none mt-4" dense>
                      <v-col>
                        <v-row v-for="(day,key) in transaction_days" :key="key" dense justify="center">
                          {{dayjs.unix(day.day_at).format(const_global.DATE_TIME_FORMAT_E) | capitalize}}
                          <v-col>
                            <v-row v-for="(event, i) in day.events" :key="i" dense>
                              <v-col>
                                <v-row dense justify="space-between">
                              <span class="cts-color-tertiary cts-font-size-3 mt-2">
                                {{dayjs.unix(event.event_at).format(const_global.DATE_TIME_FORMAT_C)}}
                              </span>
                                  <span class="cts-color-tertiary cts-font-size-3 mt-2">
                                {{dayjs.unix(event.event_at).fromNow()}}
                              </span>

                                </v-row>
                                <v-list-item dense style="border:solid 1px var(--card-border);border-radius: 4px"
                                             three-line>
                                  <v-list-item-avatar>
                                    <v-icon color="var(--primary)">{{getEventIcon(event.key)}}</v-icon>
                                  </v-list-item-avatar>
                                  <v-list-item-content class="py-1 pl-2"
                                                       style="border-left: 1px solid var(--card-border)">
                                    <v-list-item-title class="font-weight-medium">
                                      {{event.event | capitalize}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle :title="event.subtitle"
                                                          class="cts-font-size-4">
                                      {{event.subtitle | capitalize}}
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-timeline v-for="(day, key) in transaction_days" :key="key" class="pt-0 d-none d-md-block">
                      <v-timeline-item
                        color="var(--primary-alternative)" hide-dot
                        right>
                        <template v-slot:opposite>
                          <h2>
                            {{dayjs.unix(day.day_at).format(const_global.DATE_TIME_FORMAT_E) | capitalize}}</h2>
                        </template>
                      </v-timeline-item>

                      <v-timeline-item
                        v-for="(event, i) in day.events"
                        :key="i"
                        :icon="getEventIcon(event.key)"
                        :icon-color="getEventColor(event.key)"
                        class="py-0 event-timeline-row"
                        fill-dot
                        right
                      >
                        <template v-slot:opposite>
                          <span> {{dayjs.unix(event.event_at).format(const_global.DATE_TIME_FORMAT_C)}}</span>
                        </template>
                        <v-row justify="space-between">
                          <v-list-item three-line>
                            <v-list-item-content>
                              <v-list-item-title class="font-weight-medium">
                                {{event.event | capitalize}}

                              </v-list-item-title>
                              <v-list-item-subtitle :title="event.subtitle"
                                                    class="cts-font-size-4">
                                <v-icon
                                  v-if="event.intervener"
                                  :color="getIntervenerColor(transaction_content.interveners.findIndex(i => i.client_id == event.intervener))"
                                  small>
                                  person
                                </v-icon>
                                {{event.subtitle | capitalize}}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                  <v-col v-else-if="transactionStatus.id === 'error'" class="pl-md-14 py-4">
                                    <span
                                      class="cts-color-tertiary cts-font-size-3">{{$vuetify.lang.t('$vuetify.notEventsRegistered')}}</span>
                  </v-col>
                  <v-col v-else class="pl-md-14 py-4">
                                    <span
                                      class="cts-color-tertiary cts-font-size-3">{{$vuetify.lang.t('$vuetify.notEvents')}}</span>
                  </v-col>
                </v-col>
              </v-row>
              <v-row v-if="lastUpdate" class="pl-12" dense justify="center">
                <v-col class="text-center pt-4" style="border-top: 1px solid var(--card-border)">
                <span
                  class="cts-font-size-3 cts-color-tertiary font-italic">{{$vuetify.lang.t('$vuetify.lastUpdate')}}: {{lastUpdate.format(const_global.DATE_TIME_FORMAT_A)}}</span>
                </v-col>
              </v-row>
              <v-row dense justify="center">
                <v-btn :loading="updateButtonLoading" color="var(--primary-alternative)" text
                       @click="getTransactionData()">
                  <v-icon>refresh</v-icon>
                  {{$vuetify.lang.t('$vuetify.update')}}
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card outlined>
                <v-card-text>
                  {{$vuetify.lang.t('$vuetify.actions')}}
                  <v-divider class="my-2"/>
                  <v-row dense>
                    <v-col class="px-2">
                      <v-row class="overflow-hidden my-2" dense>
                        <v-btn
                          :disabled="!transaction_content.certification || transaction_content.certification === '0' || transaction_content.certification === '' || !checkPermission(const_permissions.TRANSACTION_DOWNLOAD_CERTIFICATE)"
                          :title="$vuetify.lang.t('$vuetify.downloadCertificate')"
                          block
                          class="cts-button-standard"
                          color="var(--primary-alternative)"
                          small
                          @click="getTransactionCertification(transaction_content.certification)">
                          <v-row dense justify="center">
                            {{$vuetify.lang.t('$vuetify.downloadCertificate')}}
                          </v-row>
                        </v-btn>
                      </v-row>
                      <v-row v-if="availableCancel" class="overflow-hidden" dense>
                        <v-btn
                          :disabled="!availableCancel"
                          block
                          class="cts-button-standard"
                          color="var(--primary-alternative)"
                          small
                          @click="cancelDialog = true">
                          {{$vuetify.lang.t('$vuetify.cancelTransaction')}}
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mt-4" outlined>
                <v-card-text>
                  <v-row class="px-2" dense justify="space-between">
                  <span class="primary--text cts-font-size-2">
                {{$vuetify.lang.t('$vuetify.files')}}
                  </span>
                    <span
                      class="cts-font-size-2">({{documentsList.length}})</span>

                  </v-row>
                  <v-divider class="my-2"/>
                  <v-row dense>
                    <v-col class="px-2">
                      <v-row class="my-2" dense>
                        <span>{{$vuetify.lang.t('$vuetify.transactionFilesParagraph')}}</span>
                      </v-row>
                      <TransactionFilesList
                        :certificateVisible="transaction_content.certification !== '0'"
                        :files="documentsList"
                        @certificate="getTransactionCertification(transaction_content.certification)"/>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <TransactionEventsDetails
          v-if="eventsInfoDialog"
          :events="transaction_days.map(day => day.events).flat()"
          @close="eventsInfoDialog=false"/>
      </v-row>

    </v-fade-transition>
    <v-dialog v-if="cancelDialog" v-model="cancelDialog" width="600">
      <v-card class="pa-8 pb-4">
        <v-card-text>
          <v-row class="mb-4" dense justify="center">
            <v-icon color="var(--error)" large>warning</v-icon>
          </v-row>
          <p class="cts-font-size-2 text-center">{{$vuetify.lang.t('$vuetify.cancelTransactionAdvertisement')}}</p>
        </v-card-text>
        <v-card-actions>
          <v-row dense justify="center">
            <v-btn class="mr-2" color="var(--primary)" outlined @click="cancelDialog = false">
              {{$vuetify.lang.t('$vuetify.back')}}
            </v-btn>
            <v-btn :loading="loadingCancel" color="var(--primary-alternative)" dark @click="cancelTransaction()">
              {{$vuetify.lang.t('$vuetify.cancelTransaction')}}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <v-container v-else class="cts-main-view col-12">
    <v-row v-if="transaction_content" class="ma-0" dense>
      <v-col class="pa-0" cols="12">
        <v-row class="ma-0 pa-0 justify-center" dense>
          <v-col class="mx-auto cts-view-header" cols="11" lg="10">
            <v-row class="cts-breadcrumbs ma-0 mt-1 pa-0" dense>
              <a :title="$vuetify.lang.t('$vuetify.allTransactions')" @click.prevent="backToMenu()">{{
                  $vuetify.lang.t('$vuetify.transactions')
                }} </a>
              <span class="cts-color-black_soft_text font-weight-regular ml-1">/ {{
                  $vuetify.lang.t('$vuetify.transactionDetails')
                }} </span>
            </v-row>
            <view-title
              :icon="const_icons.TRANSACTION"
              :subtitle="`${transaction_content.translated_process_name} | ${$vuetify.lang.t('$vuetify.transactionId')}: ${transaction_content.transaction_id.split('.').pop()}`"
              :title="$vuetify.lang.t('$vuetify.transactionDetails')"
              idName="transaction-detail"
            />

          </v-col>
        </v-row>
        <v-row class="pa-0 justify-center">
          <v-col class="px-4" cols="11" lg="10" xl="10">
            <v-row class="ma-0 pb-0" dense>
              <v-col class="pa-0" md="4">
                <v-row class="ma-0 justify-space-between" dense>
                  <section-title
                    :text="$vuetify.lang.t('$vuetify.transactionSummary')"
                    icon="assignment"
                    idName="transaction-detail"
                  >
                  </section-title>
                </v-row>
              </v-col>
              <v-col class="pa-0 hidden-md-and-down" cols="12" md="8">
                <v-row class="ma-0 mt-2 justify-end" dense>
                  <v-btn
                    v-if="transaction_content.postamail_data && Object.values(transaction_content.postamail_data)[0].tracking_url"
                    class="cts-button-standard my-auto" small @click="openTracking()">

                    {{$vuetify.lang.t('$vuetify.transactionTracking')}}
                    <v-icon aria-hidden="false" class="float-right" small>local_shipping</v-icon>
                  </v-btn>
                  <download-certificate
                    v-if="transaction_content.certification && transaction_content.certification !== '0' && transaction_content.certification !== '' && checkPermission(const_permissions.TRANSACTION_DOWNLOAD_CERTIFICATE)"
                    :hash="transaction_content.certification"
                    class="my-auto ml-2"
                    mode="button"
                  />
                  <v-btn
                    v-if="transaction_content.postamail_data && Object.values(transaction_content.postamail_data)[0].pee"
                    class="cts-button-standard my-auto ml-2"
                    small @click="downloadPEE()">

                    {{$vuetify.lang.t('$vuetify.transactionDownloadPee')}}
                    <v-icon aria-hidden="false" class="float-right" small>approval</v-icon>
                  </v-btn>
                </v-row>
              </v-col>
              <v-col class="pa-0 hidden-lg-and-up" cols="12" md="12">
                <v-row class="my-2 justify-space-between" dense>
                  <v-col
                    v-if="transaction_content.postamail_data && Object.values(transaction_content.postamail_data)[0].tracking_url"
                    cols="12"
                    small @click="openTracking()">
                    <v-btn class="cts-button-standard-mobile">
                      {{$vuetify.lang.t('$vuetify.transactionTracking')}}
                      <v-icon aria-hidden="false" class="float-right" small>local_shipping
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="transaction_content.postamail_data && Object.values(transaction_content.postamail_data)[0].pee"
                    cols="12">
                    <v-btn class="cts-button-standard-mobile my-2" @click="downloadPEE()">
                      {{$vuetify.lang.t('$vuetify.transactionDownloadPee')}}
                      <v-icon aria-hidden="false" class="float-right" small>approval</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="transaction_content.certification !== '0' && checkPermission(const_permissions.TRANSACTION_DOWNLOAD_CERTIFICATE)"
                    cols="12">
                    <download-certificate
                      :hash="transaction_content.certification"
                      :mobile="true"
                      mode="button"
                    >
                    </download-certificate>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="ma-0 pb-0" dense>
              <v-col class="pa-0" md="12">
                <div class="hidden-md-and-down cts-w-100">
                  <v-row class="col-11 mx-auto cts-transaction-state" dense>
                    <!-- col title document -->
                    <v-col cols="12" data-col-title-data>
                      <h5 class="px-0 mt-0 font-weight-medium text-uppercase cts-color-tertiary">
                        {{$vuetify.lang.t('$vuetify.transactionData')}}</h5>
                    </v-col>

                    <!-- col date creation -->
                    <v-col class="col-12 col-lg-6 col-xl-6" data-col-date>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{
                                                        $vuetify.lang.t('$vuetify.transactionLaunchedDate')
                                                      }}:</span>
                          <span v-if="transaction_content.last_event_ts !== 0"
                                class="cts-transaction-state-value">
                            {{dayjs.unix(transaction_content.creation_timestamp).format(const_global.DATE_TIME_FORMAT_A)}}
                          </span>
                          <span v-else
                                class="cts-transaction-state-value">{{
                              $vuetify.lang.t('$vuetify.withoutData')
                            }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{
                                                        $vuetify.lang.t('$vuetify.processName')
                                                      }}:</span>
                          <span
                            class="cts-transaction-state-value">{{
                              transaction_content.translated_process_name
                            }} </span>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{
                                                        $vuetify.lang.t('$vuetify.usernameTransactionDetail')
                                                      }}:</span>
                          <span
                            class="cts-transaction-state-value">{{
                              transaction_content.username
                            }} </span>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{
                                                        $vuetify.lang.t('$vuetify.transactionState')
                                                      }}:</span>
                          <span
                            class="cts-transaction-state-value">{{
                              capitalize(transaction_content.state)
                            }} </span>
                        </v-col>
                      </v-row>
                      <v-row v-if="transaction_content.last_event !== 'Instance_Delete'"
                             class="ma-0 pa-0"
                             dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{
                                                        $vuetify.lang.t('$vuetify.transactionLastEvent')
                                                      }}:</span>
                          <span v-if="transaction_content.last_event !== ''"
                                class="cts-transaction-state-value">{{
                              capitalize(transaction_content.last_event)
                            }}</span>
                          <span v-else
                                class="cts-transaction-state-value">{{
                              $vuetify.lang.t('$vuetify.withoutData')
                            }}</span>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0" dense>
                        <v-col class="col-12 col-lg-12 col-xl-12 px-0">
                                                    <span
                                                      class="cts-transaction-state-title">{{
                                                        $vuetify.lang.t('$vuetify.transactionLastEventDate')
                                                      }}:</span>
                          <span v-if="transaction_content.last_event_ts !== 0"
                                class="cts-transaction-state-value">
                            {{dayjs.unix(transaction_content.last_event_ts).format(const_global.DATE_TIME_FORMAT_A)}}
                          </span>
                          <span v-else
                                class="cts-transaction-state-value">{{
                              $vuetify.lang.t('$vuetify.withoutData')
                            }}</span>
                        </v-col>
                      </v-row>
                    </v-col>

                    <!-- col email -->
                    <v-col class="col-12 col-lg-6 col-xl-6" data-col-email>
                      <v-row
                        v-for="(parameter, index) in filteredTransactionParameters"
                        :key="index"
                        class="ma-0 pa-0"
                        dense
                      >
                        <v-col class="px-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                    <span class="cts-transaction-state-title">{{
                                                        parameter.translation
                                                      }}:</span>
                          <span class="cts-transaction-state-value">{{parameter.value}}</span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <!-- dialog pdf/html viewer -->
                  <v-dialog
                    v-if="showDocumentViewer"
                    v-model="showDocumentViewer"
                    max-width="1200"
                    @click:outside='handleClickModalDocument'
                  >

                    <v-card class="pa-2" tile>
                      <v-card-title class="col-lg-12 pa-2 cts-dialog-terms-header">
                        <dialog-title
                          :close="true"
                          :text="documentData.file_name"
                          event="closeModal"
                          icon="attach_file"
                          @close="handleClickModalDocument"
                        />
                      </v-card-title>
                      <v-card-text class="pb-0 mt-4">
                        <documents-viewer
                          :documentType='documentViewerFileType.split("/")[0]'
                          :fileContent="isPdf(documentData.file_name) ? `data:application/pdf;base64,${documentData.file_content}` : `data:application/text;base64,${documentData.file_content}`"
                          :fileName="documentData.file_name"
                        />
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <!--  -->

                  <!-- row datos de contacto -->
                  <v-row class="col-11 mx-auto cts-transaction-contact" dense>
                    <v-col cols="12">
                      <h5 class="px-0 mt-0 font-weight-medium text-uppercase cts-color-tertiary">
                        {{$vuetify.lang.t('$vuetify.transactionContact')}}</h5>
                    </v-col>
                    <v-row class="ma-0" dense>
                      <v-col v-for="(value, key) in transaction_recipient_data" :key="key" lg="6"
                             md="6" sm="6" xl="6">
                        <v-row class="ma-0" dense>
                          <span
                            class="cts-transaction-state-title">{{
                              $vuetify.lang.t('$vuetify.' + key)
                            }}:</span>
                          <span
                            v-if="!value || value === '' || value.indexOf('+') !== -1 && (value.replace(' ','').length === 3 || value.replace(' ','').length === 3) "
                            class="cts-transaction-state-value">{{
                              $vuetify.lang.t('$vuetify.notInformed')
                            }}</span>
                          <span v-else class="cts-transaction-state-value">{{value}}</span>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-row>

                  <!-- row recursos - documents -->
                  <v-row v-if="documentsList.length > 0"
                         class="col-11 mx-auto cts-transaction-contact">
                    <v-col class="col-12 pa-0 col-lg-4 col-xl-4">
                      <v-col class="pl-0 pb-0" cols="12">
                        <h5
                          class="px-0 mt-0 font-weight-medium text-uppercase cts-color-tertiary">
                          {{$vuetify.lang.t('$vuetify.resources')}}</h5>
                      </v-col>
                      <v-col class="col-12 col-lg-4 col-xl-4" data-col-date>
                        <v-row>
                          <ul class="list-resources d-flex flex-column pa-0">
                            <li
                              v-for='(document, id) in documentsList'
                              :key='id'
                            >
                              <div
                                class="document-card--container d-flex align-center   justify-space-between">
                                <div class="d-flex align-center"
                                     @click="handleClickDocument(document)">
                                  <img
                                    :src="documentImgSrc(document.type)"
                                    alt="Document"
                                    class="document-img"
                                  />
                                  <v-tooltip
                                    bottom
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <p
                                        class="cts-transaction-state-value mb-0"
                                        v-bind="attrs"
                                        v-on="on"
                                      >
                                        {{getDocumentName(document.key)}}
                                      </p>

                                    </template>
                                    <span> {{getDocumentName(document.key)}} </span>
                                  </v-tooltip>
                                </div>
                                <div>
                                  <button
                                    :disabled='isBtnDisabled'
                                    class="button-preview d-flex align-center"
                                    @click="handleClickDocument(document)"
                                  >
                                    <v-tooltip
                                      bottom
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          class="float-right"
                                          color="lighten"
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          {{
                                            ["pdf", "html"].includes(document.type.toLowerCase()) || Mime.getType(document.type).split("/")[0] === "image" ? const_icons.PREVISUALIZATION : "download"
                                          }}
                                        </v-icon>
                                      </template>
                                      <span>
                                        {{$vuetify.lang.t('$vuetify.viewDocument')}}</span>
                                    </v-tooltip>
                                  </button>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </v-row>
                      </v-col>
                    </v-col>
                  </v-row>
                </div>
                <div class="hidden-lg-and-up py-4">
                  <v-expansion-panels accordion class="elevation-0">
                    <v-expansion-panel class="cts-data-info-panel">
                      <v-expansion-panel-header>
                        {{$vuetify.lang.t('$vuetify.transactionGeneralData')}}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{
                                                            $vuetify.lang.t('$vuetify.transactionLaunchedDate')
                                                          }}:</span>
                            <span v-if="transaction_content.last_event_ts !== 0"
                                  class="cts-transaction-state-value">
                              {{dayjs.unix(transaction_content.creation_timestamp).format(const_global.DATE_TIME_FORMAT_A)}}
                            </span>
                            <span v-else
                                  class="cts-transaction-state-value">{{
                                $vuetify.lang.t('$vuetify.withoutData')
                              }}</span>

                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{
                                                            $vuetify.lang.t('$vuetify.processName')
                                                          }}: </span>
                            <span
                              class="cts-transaction-state-value">{{
                                transaction_content.translated_process_name
                              }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{
                                                            $vuetify.lang.t('$vuetify.usernameTransactionDetail')
                                                          }}: </span>
                            <span
                              class="cts-transaction-state-value">{{
                                transaction_content.username
                              }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{
                                                            $vuetify.lang.t('$vuetify.transactionState')
                                                          }}:</span>
                            <span
                              class="cts-transaction-state-value">{{
                                capitalize(transaction_content.state)
                              }} </span>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{
                                                            $vuetify.lang.t('$vuetify.transactionLastEvent')
                                                          }}:</span>
                            <span v-if="transaction_content.last_event !== ''"
                                  class="cts-transaction-state-value">{{
                                capitalize(transaction_content.last_event)
                              }}</span>
                            <span v-else
                                  class="cts-transaction-state-value">{{
                                $vuetify.lang.t('$vuetify.withoutData')
                              }}</span>
                          </v-col>
                        </v-row>
                        <v-row class="ma-0 pa-0" dense>
                          <v-col class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                                                        <span
                                                          class="font-weight-bold mr-2">{{
                                                            $vuetify.lang.t('$vuetify.transactionLastEventDate')
                                                          }}:</span>
                            <span v-if="transaction_content.last_event_ts !== 0"
                                  class="cts-transaction-state-value">
                              {{dayjs.unix(transaction_content.last_event_ts).format(const_global.DATE_TIME_FORMAT_A)}}
                            </span>
                            <span v-else
                                  class="cts-transaction-state-value">{{
                                $vuetify.lang.t('$vuetify.withoutData')
                              }}</span>
                          </v-col>
                        </v-row>

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-expansion-panels accordion class="elevation-0 mt-4">
                    <v-expansion-panel class="cts-data-info-panel">
                      <v-expansion-panel-header>
                        {{$vuetify.lang.t('$vuetify.transactionData')}}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row
                          v-for="(parameter, index) in filteredTransactionParameters"
                          :key="index"
                          class="ma-0 pa-0"
                          dense
                        >
                          <v-col
                            class="px-0 py-0" cols="12" lg="12" md="12" sm="12" xl="12">
                            <span class="font-weight-bold mr-2">{{parameter.key}}:</span>
                            <span class="cts-transaction-state-value">{{
                                parameter.value
                              }}</span>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <!-- expansion panel contact -->
                  <v-expansion-panels accordion class="elevation-0 mt-4">
                    <v-expansion-panel class="cts-data-info-panel">
                      <v-expansion-panel-header>{{$vuetify.lang.t('$vuetify.transactionContact')}}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row class="ma-0" dense>
                          <v-col v-for="(value, key) in transaction_recipient_data" :key="key"
                                 cols="12">
                            <v-row class="ma-0" dense>
                                                            <span class="cts-transaction-state-title">{{
                                                                $vuetify.lang.t('$vuetify.' + key)
                                                              }}:</span>
                              <span
                                v-if="!value || value === '' || value.indexOf('+') !== -1 && (value.replace(' ','').length === 3 || value.replace(' ','').length === 3) "
                                class="cts-transaction-state-value">{{
                                  $vuetify.lang.t('$vuetify.notInformed')
                                }}</span>
                              <span v-else class="cts-transaction-state-value">
                                <a v-if="key === const_global.CONTACT_PHONE" :href="'tel:' + value"
                                   class="cts-link">{{value}}</a>
                                <span v-else>{{value}}</span>
                              </span>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <v-expansion-panels v-if="documentsList.length > 0" accordion
                                      class="elevation-0 mt-4">
                    <v-expansion-panel class="cts-data-info-panel">
                      <v-expansion-panel-header>
                        {{$vuetify.lang.t('$vuetify.resources')}}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>

                        <v-row>
                          <v-col class="pt-0">

                            <v-row class="mt-2">
                              <ul class="list-resources d-flex flex-column">
                                <li
                                  v-for='(document, id) in documentsList'
                                  :key='id'
                                >
                                  <div
                                    class="document-card--container d-flex align-center   justify-space-between">
                                    <div class="d-flex align-center">
                                      <img
                                        :src="documentImgSrc(document.type)"
                                        alt="Document"
                                        class="document-img"
                                      />
                                      <v-tooltip
                                        bottom
                                      >
                                        <template
                                          v-slot:activator="{ on, attrs }">
                                          <p
                                            class="cts-transaction-state-value title-doc-expandible mb-0"
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            {{
                                              getDocumentName(document.key)
                                            }}
                                          </p>

                                        </template>
                                        <span>  {{
                                            getDocumentName(document.key)
                                          }}</span>
                                      </v-tooltip>
                                    </div>
                                    <div>
                                      <button
                                        class="button-preview d-flex align-center"
                                        @click="handleClickDocument(document)"
                                      >
                                        <v-tooltip
                                          bottom
                                        >
                                          <template
                                            v-slot:activator="{ on, attrs }">
                                            <v-icon
                                              class="float-right"
                                              v-bind="attrs"
                                              v-on="on"
                                            >
                                              {{
                                                const_icons.PREVISUALIZATION
                                              }}
                                            </v-icon>
                                          </template>
                                          <span> {{
                                              $vuetify.lang.t('$vuetify.viewDocument')
                                            }}</span>
                                        </v-tooltip>
                                      </button>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-col>
            </v-row>

            <v-col v-if="checkPermission(const_permissions.TRANSACTION_SHOW_EVENTS)" class="pa-0">
              <v-row class="ma-0 pa-0" dense>
                <v-col class="pa-0" md="12">
                  <section-title
                    :text="$vuetify.lang.t('$vuetify.transactionEvents')"
                    icon="fact_check"
                    idName="transaction-detail"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="transaction_days.length !== 0 && checkPermission(const_permissions.TRANSACTION_SHOW_EVENTS)"
              class="pa-0 mb-6"
            >
              <v-row
                v-for="(day, key) in transaction_days"
                :key="key"
                class="ma-0 py-0 justify-center"
                dense
              >
                <v-col class="px-0">
                  <v-row class="cts-transaction-event-row-date mx-0 my-4" dense>
                    <h4 class="px-0 mt-0 font-weight-medium text-uppercase cts-color-tertiary">
                      <v-icon aria-hidden="false" class="pr-1" small>calendar_today</v-icon>
                      {{day["day_name"]}}
                    </h4>
                  </v-row>
                  <transaction-event-row
                    v-for="(item, i) in day.events"
                    :key="i"
                    :event="item"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else>
              <h5 v-if="checkPermission(const_permissions.TRANSACTION_SHOW_EVENTS)"
                  class="cts-color-tertiary">
                {{$vuetify.lang.t('$vuetify.transactionWithoutEvents')}}</h5>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else class="ma-0 justify-center" dense>
      <v-col cols="11" lg="10">
        <loading-container/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import const_global from "@/constants/global"
import const_icons from "@/constants/icons"
import ViewTitle from "@/components/structures/viewTitle"
import {
  ACTIONS as TRANSACTIONS_ACTIONS,
  NAMESPACE as TRANSACTIONS_NAMESPACE,
  STATE as TRANSACTIONS_STATE
} from "@/constants/vuex/transactions"
import const_permissions from "@/constants/permissions"
import LoadingContainer from "@/components/structures/loadingContainer"
import {ServerBus} from "@/main"
import {getDocumentBase64} from "@/services/otherServices"
import {
  cancelTransaction,
  getTransactionCertification,
  getTransactionInfo,
  getTransactionPEE
} from "@/services/transactionServices"
import {redirect} from "@/util/router_utils"
import {checkPermission} from "@/util/store_utils"
import {TRANSACTIONS} from "@/constants/router/routes"
import {capitalize, documentIsGivenExtension, generateFileDownload, showToast} from "@/util/utils"
import Mime from "@/plugins/mime"
import dayjs from "@/plugins/dayjs"
import const_events from "@/constants/events"
import DetailCard from "@/components/sections/transactions/transactionsView/DetailCard.vue";
import TitleCard from "@/components/sections/transactions/transactionsView/TitleCard.vue";
import IntervenerCard from "@/components/sections/transactions/transactionsView/IntervenerCard.vue";
import TransactionEventsDetails from "@/components/sections/transactions/TransactionEventsDetails.vue";
import TransactionEventRow from "@/components/sections/transactions/transactionEventRow.vue";
import SectionTitle from "@/components/sections/processes/processView/sectionTitle.vue";
import DocumentsViewer from "@/components/documentsViewer.vue";
import DownloadCertificate from "@/components/structures/downloadCertificate.vue";
import TransactionSectionDigitalSignature
  from "@/components/sections/transactions/transactionsView/models/TransactionSectionDigitalSignature.vue";
import DialogTitle from "@/components/structures/dialogTitle.vue";
import TransactionFilesList from "@/components/sections/transactions/transactionsView/TransactionFilesList.vue";
import {formatFileNameForBack, getIntervenerColor} from "@/plugins/helpers";

export default {
  name: "transactionView",
  components: {
    TransactionFilesList,
    TransactionSectionDigitalSignature,
    DownloadCertificate,
    DocumentsViewer,
    SectionTitle,
    TransactionEventRow,
    TransactionEventsDetails,
    IntervenerCard,
    TitleCard,
    DetailCard,
    DialogTitle,
    LoadingContainer,
    ViewTitle,
  },
  data() {
    return {
      const_icons,
      const_global,
      checkPermission,
      getTransactionInfo,
      const_permissions,
      instance_code: this.$route.params.id,
      transaction_recipient_data: {},
      transaction_days: [],
      showDocumentViewer: null,
      documentData: {},
      showTooltipText: false,
      isBtnDisabled: false,
      eventsInfoDialog: false,
      Mime,
      loading: true,
      updateButtonLoading: false,
      lastUpdate: null,
      cancelDialog: false,
      loadingCancel: false
    }
  },
  created() {
    this.getTransactionData(true)
    ServerBus.$on("closeModal", () => {
      this.handleClickModalDocument()
    })
  },
  computed: {
    availableCancel() {
      if (!checkPermission(const_permissions.SECTION_PROCESS) || this.transactionStatus.id !== 'pending') {
        return false
      }
      return !(this.transaction_content.events.find(i => i.key === 'TRANSACTION.canceled'));
    },
    transactionStatus() {
      let status = {
        id: 'pending',
        icon: const_icons.WAITING,
        color: 'var(--warning)',
        text: this.$vuetify.lang.t('$vuetify.transactionPending')
      }
      if (this.transaction_content.terminal == 1 || (this.transaction_content.certification !== '0' && this.transaction_content.certification !== '')) {
        status.id = 'finished'
        status.icon = const_icons.CHECK_CIRCLE
        status.color = 'var(--success)'
        status.text = this.$vuetify.lang.t('$vuetify.finished')
      }
      if (this.containErrorEvent()) {
        status.id = 'alert'
        status.icon = const_icons.ALERT
        status.color = 'var(--warning)'
        status.text = this.$vuetify.lang.t('$vuetify.transactionWarning')
      }
      if (this.transaction_content.transaction_id.indexOf('INVALID') !== -1) {
        status.id = 'invalid'
        status.icon = const_icons.ERROR
        status.color = 'var(--error)'
        status.text = this.$vuetify.lang.t('$vuetify.notValid')
      }
      if (this.transaction_content.intromissions.find(i => formatFileNameForBack(i.action).replace(" ", "_").toLowerCase() === 'transaccion_cancelada')) {
        status.id = 'cancelled'
        status.icon = const_icons.CANCEL
        status.color = 'var(--error)'
        status.text = this.$vuetify.lang.t('$vuetify.cancelled')
      }
      return status
    },
    behaviourLayer() {
      try {
        return JSON.parse(this.transaction_content.behavior_layer)
      } catch {
        return {}
      }
    },
    filteredTransactionParameters() {
      let behaviourLayer
      try {
        behaviourLayer = JSON.parse(this.transaction_content.behavior_layer)
      } catch {
        behaviourLayer = null
      }

      const hiddenParameters = behaviourLayer?.transactionDetailView?.hiddenParameters || []

      return this.transaction_content.parameters ? this.transaction_content.parameters.filter(x => !hiddenParameters.includes(x.key)) : []
    },
    transactionModel() {

      if (this.behaviourLayer && this.behaviourLayer.steps && this.behaviourLayer.steps[0] && this.behaviourLayer.steps[0].model) {
        return this.behaviourLayer.steps[0].model
      }
      return "standard"
    },
    documentViewerFileType() {
      if (!this.documentData.file_name) return null

      if (documentIsGivenExtension(this.documentData.file_name, "pdf")) {
        return "pdf"
      }

      if (documentIsGivenExtension(this.documentData.file_name, "html")) {
        return "html"
      }

      return Mime.getType(this.documentData.file_name.split(".")[1])
    },

    transaction_content() {
      let tc = this.$store.state[TRANSACTIONS_NAMESPACE][TRANSACTIONS_STATE.S_TRANSACTION]
      if (tc) {
        if (Object.prototype.hasOwnProperty.call(tc, const_global.EVENTS)) {
          this.formatEvents(tc)
        }
        this.formatRecipientData(tc)
      }
      if (tc != null && tc.parameters != null && tc.parameters["_COST_CENTER_"] != null) {
        delete tc.parameters["_COST_CENTER_"]
      }

      if (tc && tc.interveners && tc.interveners.length > 0) {
        tc.interveners.forEach(intervener => {
          tc.events.filter(event => {
            return event.key.indexOf('front_renderer_builder') !== -1
          }).forEach(event => {
            if (event.subtitle.includes(intervener.client_id)) {
              event.intervener = intervener.client_id
            }
          })
        })
      }
      return tc
    },

    documentsList() {
      const docList = []

      if (this.transaction_content.files == null) {
        return docList
      }

      const transactionParameters = Object.values(this.transaction_content.files)

      transactionParameters.forEach(element => {
        if (Array.isArray(element)) {
          element.forEach(value => {
            if (this.isDocument(value)) {
              const documentType = value.substring(value.lastIndexOf('.') + 1)
              docList.push({
                "key": value,
                "type": documentType
              })
            }
          })

        } else if (this.isDocument(element)) {
          const documentType = element.substring(element.lastIndexOf('.') + 1)

          docList.push({
            "key": element,
            "type": documentType
          })
        }
      })

      return docList
    },
  },
  methods: {
    getIntervenerColor,
    capitalize,
    getTransactionCertification,
    parseBehaviourLayer(behaviourLayer) {
      try {
        return JSON.parse(behaviourLayer)
      } catch {
        return null
      }
    },
    async getTransactionData(loading = false) {
      this.updateButtonLoading = true
      this.loading = loading
      await getTransactionInfo().then((response) => {
        this.$store.dispatch(`${TRANSACTIONS_NAMESPACE}/${TRANSACTIONS_ACTIONS.A_SET_TRANSACTION}`, response.data)
        this.loading = false
        this.updateButtonLoading = false
      })
      this.lastUpdate = dayjs()
    },
    cancelTransaction() {
      this.loadingCancel = true
      cancelTransaction(this.transaction_content.transaction_id).then((response) => {
        setTimeout(() => {
          showToast(response.data.error !== 0, this.$vuetify.lang.t(`$vuetify.${response.data.message}`))
          this.loadingCancel = false
          this.cancelDialog = false
          this.getTransactionData(true)
        }, 1500)

      })
    },
    getEventIcon(eventKey) {
      if (typeof const_events[this.formatEventKey(eventKey)] != "undefined" && const_events[this.formatEventKey(eventKey)].icon !== "") {
        return const_events[this.formatEventKey(eventKey)].icon
      }

      return const_icons.EVENT_DEFAULT

    },
    getEventColor(eventKey) {
      let color
      if (typeof const_events[this.formatEventKey(eventKey)] != "undefined" && const_events[this.formatEventKey(eventKey)].color !== "") {
        color = const_events[this.formatEventKey(eventKey)].color
      }
      if (!color) {
        return "var(--primary)"
      }
      return color
    },
    formatEventKey(key) {
      return key.replace(".", "_").toUpperCase()
    },
    handleClickModalDocument() {
      this.showDocumentViewer = false
      this.isBtnDisabled = false
    },

    getDocumentName(documentKey) {
      return documentKey.substring(documentKey.lastIndexOf('/') + 1)
    },

    documentImgSrc(documentType) {
      if (documentType.toLowerCase() === 'pdf') {
        return require('@/assets/images/icons/pdf.png')
      }

      if (documentType.toLowerCase() === 'html') {
        return require('@/assets/images/icons/html.png')
      }

      return require('@/assets/images/icons/other-docs.png')
      // return documentType.toLowerCase() === 'pdf' ? require('@/assets/images/icons/pdf.png') : require('@/assets/images/icons/html.png')
    },

    documentViewerLoadData(fileName, fileContent) {
      this.documentData.file_name = fileName
      this.documentData.file_content = fileContent
    },
    containErrorEvent() {
      const errorEvents = ["front_renderer_builder.transaction_invalid"]
      return this.transaction_content.events.some(event => errorEvents.includes(event.key))
    },
    async handleClickDocument(document) {
      this.isBtnDisabled = true
      this.documentData = {}

      let fileObject
      try {
        fileObject = await getDocumentBase64(document.key)
        fileObject = fileObject.data

        if (documentIsGivenExtension(fileObject.file_name, "pdf") || documentIsGivenExtension(fileObject.file_name, "html")) {
          const fileContent = this.htmlReplaceFields(fileObject, this.transaction_recipient_data)
          this.documentViewerLoadData(fileObject.file_name, fileContent)
          this.showDocumentViewer = true

        } else if (Mime.getType(fileObject.file_name.split(".")[1]).split("/")[0] === "image") {
          this.documentViewerLoadData(fileObject.file_name, fileObject.file_content)
          this.showDocumentViewer = true
        } else {
          generateFileDownload(fileObject.file_content, fileObject.file_name, this.documentViewerFileType)
        }

      } catch (error) {
        showToast(error, this.$vuetify.lang.t(`$vuetify.${error.message}`))
      }
      this.isBtnDisabled = false
    },

    htmlReplaceFields(html, recipient) {
      let editedHTML = ""
      let decodedHTML = atob(html.file_content)

      const fieldsToReplace = [...new Set(decodedHTML.match(/%%\w+%%/ig))]

      if (fieldsToReplace.length > 0) {
        fieldsToReplace.forEach(x => {
          let replacement = recipient[`contact_${x.replace(/%%/g, "")}`]

          if ([const_global.FIELD_NAME, const_global.FIELD_SURNAME].includes(x.replace(/%%/g, ""))) {
            replacement = recipient[`contact_${x.replace(/%%/g, "")}`].charAt(0).toUpperCase() + recipient[`contact_${x.replace(/%%/g, "")}`].slice(1)
          }
          editedHTML = decodedHTML.replaceAll(x, replacement)
        })

        return btoa(editedHTML)
      }

      return html.file_content
    },

    isPdf(value) {
      return documentIsGivenExtension(value, "pdf")
    },

    isDocument(value) {
      return typeof value == "string" && value !== ""
    },

    formatEvents(tc) {
      let arr_aux = []
      let dates = []

      if (tc.events !== null && typeof tc.events !== 'undefined') {

        tc.events.forEach((event) => {
          if (event.event !== "Instance_Delete") {
            if (event.event_at != null) {
              dates.push(event.event_at)
            }

            let day = {}
            const day_name = dayjs.unix(event.event_at).format(const_global.DATE_TIME_FORMAT_B)

            if (arr_aux.find(d => d.day_name === day_name) === undefined) {
              day["day_name"] = day_name
              day["day_at"] = event.event_at
              day["order"] = event.order
              day["events"] = []
              arr_aux.push(day)
            }
            arr_aux.find(d => d.day_name === day_name)["events"].push(event)


          }
        })
        const bl = this.parseBehaviourLayer(tc.behavior_layer)
        let model = "standard"
        if (bl && bl.steps && bl.steps[0] && bl.steps[0].model) {
          model = bl.steps[0].model
        }

        if (model === 'digital_signature') {

          arr_aux.forEach(day => {
            day.events.sort((a, b) => {
              if (a.event_at === b.event_at) {
                return a.order - b.order
              }
              return a.event_at - b.event_at;
            });
          })
          dates.sort((a, b) => {
            return a - b;
          });

          arr_aux.sort((a, b) => {
            return a.day_at - b.day_at;
          });
        } else {

          arr_aux.forEach(day => {
            day.events.sort((a, b) => {
              if (a.event_at === b.event_at) {
                return b.order - a.order
              }
              return b.event_at - a.event_at;
            });
          })
          dates.sort((a, b) => {
            return b - a;
          });

          arr_aux.sort((a, b) => {
            return b.day_at - a.day_at;
          });
        }
        this.transaction_days = arr_aux
      }
    },
    formatRecipientData(tc) {
      if (tc && tc.recipient_data) {
        Object.entries(tc.recipient_data).forEach(([key, value]) => {
          if (key !== const_global.CONTACT_COUNTRY_CODE) {
            this.transaction_recipient_data[key] = key === const_global.CONTACT_PHONE && value ? `+${tc.recipient_data[const_global.CONTACT_COUNTRY_CODE]} ${value}` : value
          }
        })
      } else {
        this.transaction_recipient_data = {}
      }
    },
    downloadPEE() {
      getTransactionPEE(Object.keys(this.transaction_content.postamail_data)[0])
    },
    openTracking() {
      window.open(Object.values(this.transaction_content.postamail_data)[0].tracking_url, "_blank")
    },
    backToMenu() {
      redirect(TRANSACTIONS)
    }
  },
  filters: {
    capitalize
  },
  beforeDestroy() {
    ServerBus.$off("closeModal")
  }

}
</script>

<style lang="scss">
.transaction-view-container {
  .transaction-events-container {
    color: var(--tertiary);

    .link-help {
      display: inline-flex;

    }

    .link-help:hover {
      i {
        color: var(--primary-alternative) !important;
      }

      opacity: 0.8;

    }

    .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse)::before {
      left: 217px !important;
    }

    .event-timeline-row:hover {
      background-color: #fafafa !important;
    }

    .v-timeline-item {
      justify-content: flex-end;
      padding-bottom: 0;
      padding-top: 16px;

      .v-timeline-item__body {
        max-width: -webkit-fill-available !important;

        .v-list-item__content {
          align-self: center;

          .v-list-item__title {
            margin-bottom: 4px;
          }

          .v-list-item__subtitle {
            -webkit-line-clamp: 4;
          }
        }
      }

      .v-timeline-item__dot {
        z-index: 1;
        width: 34px;
        height: 34px;

        .v-timeline-item__inner-dot {
          background-color: var(--clear) !important;
          border-width: 1px;
          border-style: solid;
          border-radius: 4px;
          border-color: var(--card-border) !important;
          box-shadow: 0px 0px 2px 0px var(--card-border) !important;

        }

        .v-icon {
          font-size: 20px;
        }

      }

      .v-timeline-item__opposite {
        width: 170px;
        max-width: 170px;
        min-width: 170px;
      }

      .intervenerTimelineCard {
        font-size: 0.8rem;
        color: var(--clear);
        border-radius: 4px;
        width: fit-content;
        padding: 4px 8px;
        display: inline-block;

        i {
          color: var(--clear) !important;
          font-size: 0.8rem;
        }
      }

    }


  }
}
</style>