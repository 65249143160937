<template>
  <v-row v-if="loader" class="ma-0" dense>
    <loading-container/>
  </v-row>
  <v-container v-else class="cts-login-container">
    <v-row v-if="federatedLogin" class="justify-center">
      <CasLogin
        :theme="theme"
      />
    </v-row>
    <v-row v-else class="justify-center">
      <LoginBox
        v-if="action == LOGIN.NAME || action == LOGIN_CAS.NAME"
        :theme="theme"
      />

      <NewPasswordBox
        v-if="action == NEW_PASSWORD.NAME || action == ACTIVATE_USER.NAME"
        :activate="action == ACTIVATE_USER.NAME"
        :theme="theme"
      />

      <RememberPasswordBox
        v-if="action == REMEMBER_PASSWORD.NAME"
        :theme="theme"
      />
    </v-row>
    <v-expand-transition>
      <v-card v-if="message" class="pa-5 cts-user-message-box">
        <span>{{message}}</span>
      </v-card>
    </v-expand-transition>
  </v-container>
</template>

<script>

import {mapGetters} from "vuex";
import LoginBox from "../components/sections/user_management/LoginBox"
import const_global from "@/constants/global"
import RememberPasswordBox from "../components/sections/user_management/RememberPasswordBox"
import NewPasswordBox from "../components/sections/user_management/NewPasswordBox"
import {
  LOGIN,
  NEW_PASSWORD,
  ACTIVATE_USER,
  REMEMBER_PASSWORD,
  WITHOUT_SUBACCOUNTS,
  LOGIN_CAS
} from "@/constants/router/routes"
import {checkMasterAccountLoginType, loginAPPCenter, validateCasTicket} from "@/services/userServices";
import CasLogin from "@/components/sections/user_management/CasLogin.vue";
import {showToast} from "@/util/utils";
import {
  ACTIONS as AUTHENTICATION_ACTIONS,
  NAMESPACE as AUTHENTICATION_NAMESPACE
} from "@/constants/vuex/authentication";
import {redirect} from "@/util/router_utils";
import LoadingContainer from "@/components/structures/loadingContainer.vue";


export default {
  name: 'UserManagement',
  props: {
    theme: String
  },
  components: {
    LoadingContainer,
    CasLogin,
    NewPasswordBox,
    RememberPasswordBox,
    LoginBox
  },
  data() {
    return {
      const_global,
      message: null,
      federatedLogin: false,
      error: null,
      loader: true,
      LOGIN,
      NEW_PASSWORD,
      ACTIVATE_USER,
      REMEMBER_PASSWORD
    }
  },
  computed:
    {
      LOGIN_CAS() {
        return LOGIN_CAS
      },
      ...mapGetters({
        appearance: 'appearance/getAppearance'
      }),
      action() {
        return this.$route.name
      }
    },
  mounted() {
    this.checkLoginFormat()
    this.visible = true

    let self = this
    setTimeout(function () {
      self.message = null

    }, 5000)


  },
  methods: {
    async checkLoginFormat() {
      this.loader = true
      if (this.$route.query.ticket) {
        validateCasTicket(this.$route.query.ticket).then(response => {
          this.$router.push({query: {}})
          if (response.data.error == 0) {
            const userData = response.data.user
            this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_LOGIN_DATA}`, userData)

            userData.group[const_global.SUBACCOUNTS].length != 0 ? window.location.reload() : redirect(WITHOUT_SUBACCOUNTS)

          } else {
            showToast(true, this.$vuetify.lang.t(`$vuetify.${response.data.message}`))
            this.checkFederatedLogin()

          }
        })
      } else if (this.$route.query['appc-token']) {
        const response = await loginAPPCenter(this.$route.query['appc-token'])
        this.$router.push({query: {}})
        if (response && response.data.error == 0) {
          this.$store.dispatch(`${AUTHENTICATION_NAMESPACE}/${AUTHENTICATION_ACTIONS.A_SET_LOGIN_DATA}`, response.data.user)
          response.data.user.group[const_global.SUBACCOUNTS].length != 0 ? window.location.reload() : redirect(WITHOUT_SUBACCOUNTS)
        } else {
          showToast(true, this.$vuetify.lang.t(`$vuetify.${response.data.message}`))
          this.checkFederatedLogin()
        }


      } else {
        await this.checkFederatedLogin()

      }
    },
    async checkFederatedLogin() {
      const result = await checkMasterAccountLoginType()
      if (result && result.data && result.data.extra) {
        this.federatedLogin = result.data.extra.federated_login
      }
      this.loader = false
    }
  }
}
</script>

<style>
html {
  overflow: hidden;
}

</style>
